import { useState } from "react"
import services from "services"

const useProductCards = () => {
  const [iframe, setIframe] = useState({ title: "", list: null })

  const fetchIframe = (businessId, title) => {
    services.bases.iframe({ business_id: businessId }).then((iframe_list) => {
      const hasJustOneIframe = iframe_list && iframe_list.length === 1
      const iframeName = hasJustOneIframe ? iframe_list[0].title : title

      setIframe({ title: iframeName, list: iframe_list })
    })
  }

  const openIframe = ({ business_id, title }) => {
    fetchIframe(business_id, title)
  }

  const closeIframe = () => {
    setIframe((prev) => ({ ...prev, list: null }))
  }

  return {
    iframe,
    openIframe,
    closeIframe,
  }
}

export default useProductCards
