import Can from "components/Can"
import PageHeader from "components/PageHeader"
import Schedule from "components/Schedule"
import ProductCards from "components/ProductCards"

import { ITEMS } from "./constants"

import "./Consignment.styl"

const Consignment = ({ pageContext: { business } }) => {
  return (
    <Can I="management" the="consignment">
      <PageHeader title="Crédito Consignado" variant="medium" />
      <section className="consignment">
        <Schedule businessId={business.business_id} />
        <ProductCards items={ITEMS} />
      </section>
    </Can>
  )
}

export default Consignment
