import { useCallback, useEffect, useReducer, useState } from "react"
import services from "services"
import auth from "utils/auth"
import date from "utils/date"
import { Reducer, INITIAL_STATE } from "./reducer"
import array from "utils/array"
import { BUSINESS_NAME } from "./constants"

const useSchedule = ({ business_id, multiproduct }) => {
  const [modal, setModal] = useState({})
  const [schedule, dispatch] = useReducer(Reducer, INITIAL_STATE)
  const user = auth.getUser()

  const fetchBusinesses = useCallback(async () => {
    const business_ids = await services.user.business({ user_id: user.user_id })

    return array.toArrayOfProperty(business_ids, "business_id")
  }, [user.user_id])

  const fetchListOfSchedule = useCallback(
    async (params) => {
      const schedule_at = params?.schedule_at

      const business_ids = multiproduct
        ? await fetchBusinesses()
        : [business_id]

      const selected = schedule_at ? schedule_at : date.getDate()

      const data = await services.user.schedule({
        business_id: business_ids,
        schedule_at_gte: selected,
        schedule_at_lte: selected,
      })

      dispatch({
        type: "FETCH_SCHEDULES",
        payload: {
          data,
          business_id,
        },
      })
    },
    [business_id, multiproduct, fetchBusinesses]
  )

  const setDates = useCallback(() => {
    const indexes = [...Array(7).keys()]

    indexes.forEach((index) => {
      if (index)
        dispatch({
          type: "SET_DATES",
          payload: {
            index,
          },
        })
    })
  }, [])

  const setSelectedValues = useCallback(() => {
    dispatch({ type: "SET_SELECTED_VALUES" })
  }, [])

  const onFilterByDay = ({ day, date: selectedDate }) => {
    fetchListOfSchedule({ schedule_at: selectedDate })
    dispatch({
      type: "FILTER_BY_DAY",
      payload: {
        day,
      },
    })
  }

  const onPagination = ({ schedulePagination }) => {
    dispatch({
      type: "PAGINATION_BETWEEN_WEEKS",
      payload: { schedulePagination },
    })
  }

  const resetSchedules = () => {
    dispatch({
      type: "RESET_SCHEDULES",
    })
  }

  const updateSchedules = useCallback(() => {
    fetchListOfSchedule()
    setDates()
    setSelectedValues()
  }, [fetchListOfSchedule, setDates, setSelectedValues])

  const openModal = ({ leadUUID, businessId }) => {
    setModal({
      active: true,
      leadUUID: leadUUID,
      business: {
        business_id: businessId,
        business_name: BUSINESS_NAME[businessId],
      },
    })
    resetSchedules()
  }

  const onCloseModal = () => {
    updateSchedules()
  }

  useEffect(() => {
    updateSchedules()
  }, [updateSchedules])

  return {
    schedules: schedule.schedules,
    month: schedule.month,
    selected: schedule.selected,
    selectedWeek: schedule.selectedWeek,
    modal: {
      ...modal,
      openModal,
      onCloseModal,
    },
    onFilterByDay,
    onPagination,
  }
}

export default useSchedule
