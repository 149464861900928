import date from "utils/date"

export const INITIAL_STATE = {
  selected: "current",
  selectedWeek: [],
  weeks: {
    previous: [],
    current: [],
    next: [],
  },
  month: null,
  schedules: null,
}

export const Reducer = (state, action) => {
  switch (action.type) {
    case "RESET_SCHEDULES": {
      return {
        ...state,
        weeks: {
          previous: [],
          current: [],
          next: [],
        },
      }
    }
    case "FETCH_SCHEDULES": {
      const { data } = action.payload

      return {
        ...state,
        schedules: data,
      }
    }
    case "SET_DATES": {
      const { weeks } = state
      const { index } = action.payload

      return {
        ...state,
        weeks: {
          ...weeks,
          previous: [
            ...weeks.previous,
            {
              day: date.getLastWeek(index),
              dayOfTheWeek: date.getWeekday(index),
              brt: date.getLastBRT(index),
              active: false,
            },
          ],
          current: [
            ...weeks.current,
            {
              day: date.getWeek(index),
              dayOfTheWeek: date.getWeekday(index),
              brt: date.getCurrentBRT(index),
              active: new Date().getDate() == date.getWeek(index),
            },
          ],
          next: [
            ...weeks.next,
            {
              day: date.getNextWeek(index),
              dayOfTheWeek: date.getWeekday(index),
              brt: date.getNextBRT(index),
              active: false,
            },
          ],
        },
      }
    }
    case "SET_SELECTED_VALUES": {
      const { weeks, selected } = state

      return {
        ...state,
        month: date.getMonth(),
        selectedWeek: weeks[selected],
      }
    }
    case "FILTER_BY_DAY": {
      const { selectedWeek } = state
      const { day } = action.payload

      return {
        ...state,
        selectedWeek: selectedWeek.map((week) => ({
          ...week,
          active: Number(day) === Number(week.day),
        })),
      }
    }
    case "PAGINATION_BETWEEN_WEEKS": {
      const { weeks } = state
      const { schedulePagination } = action.payload

      return {
        ...state,
        selected: schedulePagination,
        selectedWeek: weeks[schedulePagination].map((week) => ({
          ...week,
          active: false,
        })),
        schedules: null,
      }
    }
    default:
      return state
  }
}
