import If from "components/If"
import Can from "components/Can"
import Card from "components/Card"
import Icon from "components/Icon"
import { isPending } from "./utils"
import classnames from "classnames"
import _product from "utils/product"
import LeadModal from "components/LeadModal"
import EmptyState from "components/EmptyState"

import useSchedule from "./hooks"

import "./Schedule.styl"

const Schedule = ({ businessId = "1", multiproduct = false }) => {
  const {
    schedules,
    month,
    selected,
    selectedWeek,
    modal,
    onFilterByDay,
    onPagination,
  } = useSchedule({ business_id: businessId, multiproduct })

  return (
    <Can
      business={_product.getAllBusiness({
        repeat: 2,
      })}
      do={_product.fillActions({
        action: ["leads-management", "admin-management"],
      })}
    >
      <div className="schedule">
        <Card className="schedule__card" title="Agendamentos">
          <div className="schedule__dates">
            <h1 className="schedule__month">{month}</h1>
            <If condition={selected !== "previous"}>
              <button
                aria-label="voltar"
                className="schedule__pagination schedule__pagination--previous"
                onClick={() => {
                  onPagination({
                    schedulePagination:
                      selected === "current" ? "previous" : "current",
                  })
                }}
              >
                <Icon name="arrow-previous" />
              </button>
            </If>
            <ul className="schedule__days">
              {selectedWeek.map((week) => (
                <li
                  aria-label={`${week.day} ${week.dayOfTheWeek}`}
                  className={classnames("schedule__day", {
                    "schedule__day--active": week.active,
                  })}
                  onClick={() => {
                    onFilterByDay({ day: week.day, date: week.brt })
                  }}
                  key={week.day}
                >
                  <span className="schedule__number">{week.day}</span>
                  <span className="schedule__weekday">{week.dayOfTheWeek}</span>
                </li>
              ))}
            </ul>
            <If condition={selected !== "next"}>
              <button
                className="schedule__pagination schedule__pagination--next"
                aria-label="prosseguir"
                onClick={() => {
                  onPagination({
                    schedulePagination:
                      selected === "current" ? "next" : "current",
                  })
                }}
              >
                <Icon name="arrow-default" />
              </button>
            </If>
          </div>
          <If condition={!schedules?.length}>
            <EmptyState text="Sem agendamentos" />
          </If>
          <If condition={schedules && schedules.length}>
            <ul className="schedule__list">
              {schedules?.map((schedule, index) => (
                <li
                  key={index}
                  className="schedule__item"
                  aria-label="Descrição do agendamento"
                >
                  <Card
                    className="schedule__card-info"
                    onClick={() => {
                      modal.openModal({
                        leadUUID: schedule.lead_uuid,
                        businessId: schedule.business_id,
                      })
                    }}
                    key={schedule.lead_uuid}
                  >
                    <header className="schedule__header">
                      <span
                        aria-label={schedule.format_schedule_at}
                        className={classnames("schedule__time-and-weekday", {
                          "schedule__time-and-weekday--late": isPending(
                            schedule.format_schedule_at
                          ),
                        })}
                      >
                        <Icon name="clock" className="schedule__header--icon" />
                        {schedule.schedule_time}
                      </span>
                      <span className="schedule__business">
                        {schedule.business_name}
                      </span>
                    </header>
                    <p className="schedule__name">{schedule.lead_name}</p>
                    <span className="schedule__phone">
                      {schedule.phone_number}
                    </span>
                    <If condition={schedule.observations}>
                      <p className="schedule__observations">
                        <span>Obs</span>
                        {schedule.observations}
                      </p>
                    </If>
                  </Card>
                </li>
              ))}
            </ul>
          </If>
        </Card>
        <LeadModal
          active={modal.active}
          leadUUID={modal.leadUUID}
          business={modal.business}
          onClose={modal.onCloseModal}
        />
      </div>
    </Can>
  )
}

export default Schedule
