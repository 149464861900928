import { Link } from "gatsby"
import If from "components/If"
import Can from "components/Can"
import Icon from "components/Icon"
import Card from "components/Card"
import classnames from "classnames"
import Iframe from "components/Iframe"
import Text from "components/Typography/Text"

import useProductCards from "./hooks"

import "./ProductCards.styl"

const CardText = ({ title, icon }) => (
  <>
    <Text className="cards-list__card-content" variant="big">
      <Icon name={icon} />
      {title}
    </Text>
    <Icon className="cards-list__card-arrow" name="arrow-right" />
  </>
)

const CardContent = ({ item, openIframe }) => (
  <li
    className={classnames("cards-list__card", {
      [`cards-list__card--hide-${item.hidden}`]: !!item.hidden,
      [`cards-list__color-${item.color}`]: !!item.color,
      "cards-list__card--full": ["iframe", "full-link"].includes(item.type),
    })}
  >
    <Card spacing="medium" radius="small">
      <div className="cards-list__action">
        <If
          condition={item?.profiles}
          renderIf={item?.profiles?.map((permission) => (
            <Can profile={permission.profile} see="it" key={permission.profile}>
              <If condition={["full-link", "link"].includes(permission.type)}>
                <Link
                  to={permission.link}
                  data-test={`LinkToggle: ${item.title}`}
                >
                  <CardText title={item.title} icon={item.icon} />
                </Link>
              </If>
              <If condition={permission.type === "iframe"}>
                <button
                  data-test={`IframeToggle: ${item.title}`}
                  onClick={() => openIframe(permission.iframe_info)}
                >
                  <CardText title={item.title} icon={item.icon} />
                </button>
              </If>
            </Can>
          ))}
          renderElse={
            <>
              <If condition={["full-link", "link"].includes(item.type)}>
                <Link to={item.link} data-test={`LinkToggle: ${item.title}`}>
                  <CardText title={item.title} icon={item.icon} />
                </Link>
              </If>
              <If condition={item.type === "iframe"}>
                <button
                  data-test={`IframeToggle: ${item.title}`}
                  onClick={() => openIframe(item.iframe_info)}
                >
                  <CardText title={item.title} icon={item.icon} />
                </button>
              </If>
            </>
          }
        />
      </div>
    </Card>
  </li>
)

const ProductCards = ({ className, items = [] }) => {
  const { iframe, openIframe, closeIframe } = useProductCards()

  return (
    <>
      <ul className={classnames("cards-list", className)}>
        {items.map((item, index) => (
          <If
            condition={item?.permission}
            renderIf={
              <Can
                subject={item?.permission?.subject}
                action={item?.permission?.action}
              >
                <CardContent
                  item={item}
                  openIframe={openIframe}
                  data-test={`CardContent: ${item?.title}`}
                />
              </Can>
            }
            renderElse={<CardContent item={item} openIframe={openIframe} />}
            key={index}
          />
        ))}
      </ul>
      <If condition={iframe?.list && iframe.list.length > 0}>
        <Iframe
          iframes={iframe?.list}
          title={iframe?.title}
          closeIframe={closeIframe}
        />
      </If>
    </>
  )
}

export default ProductCards
