import { useCallback, useEffect, useState } from "react"
import { reduceIframeListToRecord } from "./utils"

const useIframe = ({ iframes }) => {
  const [currentIframe, setCurrentIframe] = useState(null)
  const [iframeRecord, setIframeRecord] = useState({})
  const [tabs, setTabs] = useState([])

  const onSelectTab = (titleSelected) => {
    setCurrentIframe(iframeRecord[titleSelected])
  }

  const getTabsByIframes = (iframeList) => {
    const tabList = iframeList.map((iframe) => ({
      name: iframe?.title,
      id: iframe?.title,
    }))
    setTabs(tabList)
  }

  const turnIframeListIntoRecordByTitle = (iframeList) => {
    let iframe_record = reduceIframeListToRecord(iframeList)

    setIframeRecord(iframe_record)
  }

  const setFirstIframeTheCurrentOne = useCallback(
    (iframeList) => {
      if (!currentIframe && iframeList?.length > 0) {
        setCurrentIframe(iframeList[0])
      }
    },
    [currentIframe]
  )

  useEffect(() => {
    getTabsByIframes(iframes)
    turnIframeListIntoRecordByTitle(iframes)
  }, [iframes])

  useEffect(() => {
    setFirstIframeTheCurrentOne(iframes)
  }, [iframes, setFirstIframeTheCurrentOne])

  return {
    tabs,
    onSelectTab,
    currentIframe,
    hasTabs: iframes && iframes.length > 1,
  }
}

export default useIframe
