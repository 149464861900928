import { Link } from "gatsby"
import Can from "components/Can"
import If from "components/If"

import classnames from "classnames"

import "./Tabs.styl"

export const TabsNavigation = ({ tabs }) => (
  <If condition={tabs.length}>
    <section className="tab-bar">
      <ul className="tab-bar__list">
        {tabs.map(({ name, path, permission }) => (
          <If
            key={name}
            condition={permission}
            renderIf={
              <Can I={permission?.subject} the={permission?.action}>
                <li className="tab-bar__item" data-test={name}>
                  <Link
                    className="tab-bar__link"
                    activeClassName="tab-bar__link--active"
                    to={path}
                  >
                    {name}
                  </Link>
                </li>
              </Can>
            }
            renderElse={
              <li className="tab-bar__item" data-test={name}>
                <Link
                  className="tab-bar__link"
                  activeClassName="tab-bar__link--active"
                  to={path}
                >
                  {name}
                </Link>
              </li>
            }
          />
        ))}
      </ul>
    </section>
  </If>
)

export const TabsOptions = ({ tabs = [], onSelect, selectedId }) => {
  return (
    <If condition={tabs.length}>
      <section className="tab-bar">
        <ul className="tab-bar__list">
          {tabs.map(({ name, id }) => (
            <li
              key={`${name}`}
              className={classnames("tab-bar__item tab-bar__options", {
                "tab-bar__options--active": id === selectedId,
              })}
              onClick={() => onSelect(id)}
            >
              {name}
            </li>
          ))}
        </ul>
      </section>
    </If>
  )
}
