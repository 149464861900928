import If from "components/If"
import useIframe from "./hooks"
import Icon from "components/Icon"
import classnames from "classnames"
import { TabsOptions } from "components/Tabs"

import "./Iframe.styl"

const Iframe = ({ title = "", closeIframe, iframes = [], ...props }) => {
  const { tabs, onSelectTab, currentIframe, hasTabs } = useIframe({ iframes })

  return (
    <div className="modal-iframe" {...props}>
      <div className="modal-iframe__container" role="dialog">
        <header className="modal-iframe__header">
          <div className="modal-iframe__header--row">
            <span className="modal-iframe__title">{title}</span>
            <button
              onClick={closeIframe}
              data-test="close-modal-iframe"
              className="modal-iframe__close"
            >
              <Icon name="close" />
            </button>
          </div>

          <If condition={hasTabs}>
            <div className="modal-iframe__header--row">
              <TabsOptions
                tabs={tabs}
                onSelect={onSelectTab}
                selectedId={currentIframe?.title}
              />
            </div>
          </If>
        </header>

        <iframe
          src={currentIframe?.src}
          title={currentIframe?.title}
          className={classnames("modal-iframe__src", {
            "modal-iframe__src--with-tab": hasTabs,
          })}
          data-test={`Iframe: ${title}`}
        ></iframe>
      </div>
    </div>
  )
}

export default Iframe
